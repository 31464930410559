<template>
  <span>
    <v-card flat class="elevation-0" :rounded="false">
      <v-card-text>
      <v-data-table
      :headers="headers"
      :items="listOfSales"
      :server-items-length="totalCount"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      elevation="0"
      >
        <template v-slot:[`item.isSent`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar :color="getSentToSefColor(item.isSent)" size="15" v-bind="attrs" v-on="on"></v-avatar>
            </template>
            <span>{{getSentToSefTooltip(item.isSent)}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.partnerCompany`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ getShortenedStr(item.partnerCompany)}}</div>
            </template>
            <span>{{item.partnerCompany}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.edexStatusMessage`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ item.isSent ? '' : getShortenedStr(item.edexStatusMessage)}}</div>
            </template>
            <span>{{item.edexStatusMessage}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.sefComment`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ getShortenedStr(item.sefComment)}}</div>
            </template>
            <span>{{item.sefComment}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.actualDeliveryDate`]="{ item }">
          <div>{{formatDate(item.actualDeliveryDate)}}</div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{formatDate(item.createdAt)}}</div>
        </template>

        <template v-slot:[`item.sentDate`]="{ item }">
          <div>{{formatDate(item.sentDate)}}</div>
        </template>

        <template v-slot:[`item.approveDate`]="{ item }">
          <div>{{formatDate(item.approveDate)}}</div>
        </template>

        <template v-slot:[`item.rejectDate`]="{ item }">
          <div>{{formatDate(item.rejectDate)}}</div>
        </template>

        <template v-slot:[`item.stornoDate`]="{ item }">
          <div>{{formatDate(item.stornoDate)}}</div>
        </template>

        <template v-slot:[`item.isIndividualVat`]="{ item }">
          <v-icon> {{ item.isIndividualVat ? 'mdi-check' : '' }} </v-icon>
        </template>
      </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn class="primary" @click="downloadExcelFile" :loading="downloadingExcel">
          Preuzmi
          <v-icon class="ml-2">mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </span>
</template>

<style lang="css">
td {
  padding: 0 !important;
  padding-left: 10px !important;
  height: 30px !important;
}

th {
  padding: 0 !important;
  padding-left: 10px !important;
}
</style>

<script>
import * as apiDocuments from '../api/documents.js';

export default {
  name: "ListOutgoingDocuments",
  data: function () {
    return {
      loading: false,
      downloadingExcel: false,
      options: {
        sortDesc: [true],
        itemsPerPage: 20,
      },
      queryString: null,
      listOfSales: [],
      totalCount: 1,
      headers: [
          { text: 'Broj', value: 'documentNumber', width: "100px", sortable: false },
          { text: 'Datum prometa', value: 'actualDeliveryDate', width: "100px", sortable: false },
          { text: 'Datum prijema', value: 'createdAt', width: "100px", sortable: false },
          { text: 'Datum slanja na SEF', value: 'sentDate', width: "90px", sortable: false },
          { text: 'Tip', value: 'documentType', width: "50px", sortable: false },
          { text: 'Kupac', value: 'partnerCompany', width: "150px", sortable: false },
          { text: 'Vrednost', value: 'totalAmount', width: "100px", sortable: false },
          { text: 'Poslato na SEF', value: 'isSent', width: "50px", sortable: false },
          { text: 'SEF ID', value: 'sefId', width: "100px", sortable: false },
          { text: 'SEF status', value: 'sefStatusMessage', width: "100px", sortable: false },
          { text: 'Greška', value: 'edexStatusMessage', width: "180px", sortable: false },
          { text: 'Razlog odbijanja', value: 'sefComment', width: "150px", sortable: false },
          { text: 'CRF status', value: 'cirStatus', width: "80px", sortable: false },
          { text: 'Pojedinačna prijava PDV', value: 'isIndividualVat', width: "50px", sortable: false },
          { text: '', value: 'sendToAmaps', width: "50px", sortable: false },
          { text: 'Osn. 10% PDV', value: 'pdv10Base', width: "100px", align: "right", sortable: false },
          { text: 'Iznos 10% PDV', value: 'pdv10Amount', width: "100px", align: "right", sortable: false },
          { text: 'Osn. 20% PDV', value: 'pdv20Base', width: "100px", align: "right", sortable: false },
          { text: 'Iznos 20% PDV', value: 'pdv20Amount', width: "100px", align: "right", sortable: false },
          { text: 'Datum prihvatanja', value: 'approveDate', width: "100px", sortable: false },
          { text: 'Datum storniranja', value: 'stornoDate', width: "100px", sortable: false },
          { text: 'Datum odbijanja', value: 'rejectDate', width: "100px", sortable: false },
      ]
    }
  },
  mounted() {
   // this.getSalesFromAPI();

  },
  watch: {
    options: {
      handler () {
        this.getSalesFromAPI();
      },
      deep: true,
    },
    filters: {
      handler(newFilters) {
        this.filters = newFilters;
        this.options.page = 1;
        this.getSalesFromAPI();
      },
      deep: true
    }
  },
   props: {
    filters: {
      type: Object,
      default: null,
      required: false
    }
  },
  methods: {
    getSalesFromAPI() {
      this.loading = true;

      apiDocuments.getOutgoingDocuments(this.options, this.filters, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.totalCount = responseData.totalCount;
          this.listOfSales = responseData.data;
        })
        .catch(() => {
          this.listOfSales = [];
          this.totalCount = 0;
          this.loading = false;
        })
    },

    getSentToSefTooltip(isSent) {
      if (isSent === null) {
        return '';
      } else if (isSent === true) {
        return 'Da';
      } else {
        return 'Ne';
      }
    },

    getSentToSefColor(isSent) {
      if (isSent === null) {
        return 'surface-variant';
      } else if (isSent === true) {
        return 'success';
      } else {
        return 'error';
      }
    },

    formatDate(datum) {
      if (datum) {
        return datum.replaceAll('-', '.');
      } else {
        return '';
      }
    },

    getShortenedStr(str) {
      if (str === null) {
        return '';
      }

      const maxLength = 50;
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
      }

      return str;
    },

    downloadExcelFile() {
      this.downloadingExcel = true;

      const endpoint = '/report/outgoing';

      const requestHeaders = new Headers();
      requestHeaders.set('Authorization', 'Bearer ' + this.$store.getters.token);

      const requestOptions = { method: 'POST' };
      requestOptions.headers = requestHeaders;

      let bodyString = JSON.stringify(this.filters);
      requestOptions.body = bodyString;
      requestHeaders.set('Content-Type', 'application/json');

      // do the fetch
      let filename = "";
      const apiBaseUrl = process.env.VUE_APP_API_BASEURL;
      return fetch(apiBaseUrl + endpoint, requestOptions)
        .then(response => {
          const header = response.headers.get('Content-Disposition');
          const parts = header.split(';');
          filename = parts[1].split('=')[1].replaceAll("\"", "");

          return response.blob()
            .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();  //afterwards we remove the element again
              this.downloadingExcel = false;
            });
        })
        .catch((e) => {
          this.downloadingExcel = false;
          console.log(e);
        });
    },
  }
}
</script>

<style scoped>
.v-data-table {
  box-shadow: none !important;
}
</style>
